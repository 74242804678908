<div class="bloc">
    <div class="element">
    <h2>Merci pour votre commande, vous allez recevoir un e-mail quand votre commande sera prete</h2>
    <div>
        <img class="image" src="/assets/img/validation.png">
    </div>
    </div>
    <div class="element">
    <button routerLink="/home" class="btn-primary btn">Retour à l'accueil</button>
    </div>
    <div class="element">
    <h3>Vous allez redirigé vers l'accueil dans {{ secondes }} secondes</h3>
    </div>
</div>
