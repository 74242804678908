<div class="dropdown filtre">
    <button class="btn btn-secondary btn-lg dropdown-toggle" type="button" data-bs-toggle="dropdown">
      Univers
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><a class="dropdown-item" (click)="setFilter('univers', 'Marvel')">Marvel</a></li>
        <li><a class="dropdown-item" (click)="setFilter('univers', 'DC Comics')">DC Comics</a></li>
    </ul>
    <button class="btn btn-secondary btn-lg dropdown-toggle" type="button" data-bs-toggle="dropdown">
      Catégorie
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><a class="dropdown-item" (click)="setFilter('categorie', 'Super-héros')">Super-héros</a></li>
        <li><a class="dropdown-item" (click)="setFilter('categorie', 'Super-vilains')">Super-vilains</a></li>
        <li><a class="dropdown-item" (click)="setFilter('categorie', 'Super-girls')">Super-girls</a></li>
        <li><a class="dropdown-item" (click)="setFilter('categorie', 'Anti-héros')">Anti-héros</a></li>
    </ul>
    <button class="btn btn-secondary btn-lg dropdown-toggle" type="button" data-bs-toggle="dropdown">
      Disponibilité
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><a class="dropdown-item" (click)="setFilter('disponibilite', '1')">Disponible</a></li>
        <li><a class="dropdown-item" (click)="setFilter('disponibilite', '2')">Indisponible</a></li>
        <li><a class="dropdown-item" (click)="setFilter('disponibilite', '3')">Précommande</a></li>
    </ul>
    <button class="btn btn-secondary btn-lg dropdown-toggle" type="button" data-bs-toggle="dropdown">
      Héros
    </button>
    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Harley Quinn')">Harley Quinn</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Batman')">Batman</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Wonder Woman')">Wonder Woman</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Spiderman')">Spiderman</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Deadpool')">Deadpool</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Joker')">Joker</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Superman')">Superman</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Marvel Legacy')">Marvel Legacy</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Captain America')">Captain America</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Docteur Strange')">Docteur Strange</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Captain Marvel')">Captain Marvel</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Daredevil')">Daredevil</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Avengers')">Avengers</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Teen Titans')">Teen Titans</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'X Men')">X Men</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Iron Man')">Iron Man</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Hulk')">Hulk</a></li>
        <li><a class="dropdown-item" (click)="setFilter('heros', 'Marvel Zombies')">Marvel Zombies</a></li>
    </ul>   
  </div>
