<div class="conteneur-global">
    <div class="fond">
        <h2 class="text-center rouge">Connexion</h2>
        <div class="d-flex flex-column align-items-center justify-content-center bandeau">
            <h3>Déjà inscrit ?</h3>
            <p>Connectez-vous !</p>
        </div>
        <form [formGroup]="signinForm" (ngSubmit)="onSubmit()" class="formulaire">
            <div class="form-group mt-2">
                <label for="email" class="rouge">Adresse mail</label>
                <input type="text" id="email" class="form-control" formControlName="email">
                <!-- /////////////////////////////////////// -->
                <div *ngIf="signinForm.controls['email'].invalid && (signinForm.controls['email'].dirty || signinForm.controls['email'].touched)"
                class="alert alert-danger">
                <div *ngIf="signinForm.controls['email'].errors?.maxlength">
                    255 caracteres maximum.
                </div>
                <div *ngIf="signinForm.controls['email'].errors?.email">
                    Mauvais format (email@email.mail)
                </div>
                <div *ngIf="signinForm.controls['email'].errors?.required">
                    Votre email est obligatoire !
                </div>
            </div>
            <!-- /////////////////////////////////////// -->
            </div>
            <div class="form-group">
                <label for="password" class="rouge">Mot de passe</label>
                <input type="password" id="password" class="form-control" formControlName="password">
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-primary mt-2" type="submit" [disabled]="signinForm.invalid">Connexion ></button> <!--bouton desactivé tant que le regex des infos n'est pas bon-->
            </div>
        </form>
        <p class="text-danger">{{ errorMessage }}</p>
    </div>
    <div class="d-flex flex-column fond">
        <h2 class="text-center rouge">Inscription</h2>
        <div class="d-flex flex-column align-items-center justify-content-center bandeau">
            <h3>Nouveau client ?</h3>
            <p>Inscrivez-vous !</p>
        </div>
        <div class="d-flex justify-content-center">
            <a class="btn btn-primary mt-5 compte d-flex align-items-center justify-content-center" routerLink="/auth/signup">Créer un compte</a>
        </div>
    </div>
</div>
