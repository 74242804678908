<app-modal-validation></app-modal-validation>
<div class="main">
    <div style="background-color: red; width: 100%; height: 150px; "><p style="font-size: 42px; color: white; font-weight: bold; text-align: center;">ATTENTION CE SITE EST UN SITE FACTICE ET NE VENDS AUCUN PRODUITS</p></div>
    <app-header></app-header>
    <div class="d-flex justify-content-center align-items-center">
        <app-ng-dynamic-breadcrumb [bgColor]="'#c4c4c448'" [fontColor]="'#BE2537'" [symbol]="' | '"></app-ng-dynamic-breadcrumb>
    </div> 
    <router-outlet></router-outlet>
    <app-footer></app-footer>
</div>
