<div class="grid-container">
    <div class="LogoFooter">
        <img src="../../assets/img/LogoWhite.png" class="logo-du-footer" alt="logo Edward Comics">
    </div>
    <div class="Apropos">
        <h3>A propos</h3>
        <ul class="list-unstyled">
            <li>CGV</li>
            <li>Protection des données</li>
            <li>Plan du site</li>
        </ul>
    </div>
    <div class="Contacts">
        <h3>Contacts</h3>
        <ul class="list-unstyled">
            <li>edward@comics.com</li>
            <li>0 800 520 652</li>
            <li routerLink="contact">Contactez-nous</li>
        </ul>
    </div>
    <div class="Adresse">
        <h3>Adresse</h3>
        <ul class="list-unstyled">
            <a class="text-decoration-none" href="https://goo.gl/maps/FBKKQaUiYWk5ohUf7">
                <li>125 Boulevard des peuplier</li>
                <li>83000 TOULON</li>
                <li>France</li>
            </a>
            <a href="assets/img/easterEgg.png" style="text-decoration: none;"><li>Surprise</li></a>
        </ul>
    </div>
    <div class="Horaires">
        <h3>Horaires</h3>
        <ul class="list-unstyled">
            <li>Ouvert du lundi au samedi</li>
            <li>De 9h30 à 18h30</li>
        </ul>
    </div>
    <div class="Meteo">
        <h3>Météo</h3>
        <!-- <app-meteo></app-meteo> -->
        <p class="rouge">Suite a l'incendie d'OVH la météo est indisponible !</p>
    </div>
</div>
